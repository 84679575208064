// import Vue from 'vue'
import { Component, Watch } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { TextValueExtended, TextValueItem } from "../../../models/TextValueItem";
import { EseguiComandoRequest } from "../../../models/comandi/eseguiComandoRequest";
import { AnagraficaDistributoreEdit } from "@/models/anafrafica/AnagraficaDistributore";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.DISTRIBUTORI;
@Component({})
export default class anagraficaDistributoriPage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Anagrafica.DISTRIBUTORI];
	}
	tabPemessi(tab: string): boolean {
		if (tab == "utenti") return this.hasPermesso(permessi.BK.Anagrafica.UTENTI);
		else if (tab == "prodotti") return this.hasPermesso(permessi.BK.Prodotti.PRODOTTI);
		else if (tab == "commerciali") return this.hasPermesso(permessi.BK.Commerciali.COMMERCIALI);
		else if (tab == "crediti") return this.canEdit;
		else return true;
	}
	codiciPagamento: any[] = [];
	province: TextValueExtended[] = [];
	regioni: TextValueExtended[] = [];
	lingue: TextValueItem[] = [];
	distributori: TextValueItem[] = [];
	selectedTest: any = null;
	editItem: AnagraficaDistributoreEdit = new AnagraficaDistributoreEdit();
	showEditModal: boolean = false;
	currentItem: any = {};
	tipiDistributore: TextValueItem[] = [];
	showOnlyActive: boolean = true;
	hintTipoDistributore: string = "";
	hideLimiteCredito: boolean = false;

	actionsToDo: TextValueItem[] = [
		{ value: null, text: "Esegui su selezione" },
		{ value: 1, text: "Propaga Prodotti ai Figli" },
		{ value: 2, text: "Eredita Prodotti dal Padre" }
	];

	selectedActionToDo: number = null;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 80, filterable: true, filterType: "numeric-int", cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "ragioneSociale",
				title: this.$i18n.t("generico.ragioneSociale").toString(),
				attributes: {
					class: "rsClick",
					style: "font-weight: bold;"
				},
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true
			},
			{
				field: "distributorePadreID",
				title: this.$i18n.t("generico.padre").toString(),
				width: 100,
				groupable: true,
				hidden: false,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				sortBy: "distributorePadre",
				values: this.distributori,
				filterable: true,
				itemsForFilter: this.distributori,
				filterType: "select",
				editType: "select",
				editSource: this.distributori,
				editSourceText: "text",
				editSourceValue: "value"
			},
			{
				field: "tipoDistributoreID",
				title: this.$i18n.t("generico.tipo").toString(),
				width: 100,
				groupable: true,
				values: this.tipiDistributore,
				cell: "defaultCellTemplate",
				sortBy: "tipoDistributore",
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				itemsForFilter: this.tipiDistributore,
				filterType: "select",
				editType: "select",
				editSource: this.tipiDistributore,
				editSourceText: "text",
				editSourceValue: "value",
				editSelectionCallBack: this.onChangeTipoDistributore,
				hint: this.hintTipoDistributore
			},
			{
				field: "tipoDistributore",
				title: this.$i18n.t("generico.tipo").toString(),
				export: true,
				hidden: true,
				editHide: true
			},
			//  { field: "GruppoID", title: "Gruppo", width: 100, values: gruppi , groupable: true , hidden: true },
			{ field: "email", title: this.$i18n.t("generico.email").toString(), width: 150, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true },
			{ field: "email2", title: this.$i18n.t("generico.email2").toString(), width: 150, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },
			{ field: "email3", title: this.$i18n.t("generico.email3").toString(), width: 150, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },
			{ field: "codiceFiscale", title: this.$i18n.t("generico.codiceFiscale").toString(), width: 100, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },
			{ field: "telefono", export: true, title: this.$i18n.t("generico.telefono").toString(), width: 100, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },
			{ field: "telefono2", title: this.$i18n.t("generico.telefono2").toString(), width: 100, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },
			{ field: "telefono3", title: this.$i18n.t("generico.telefono3").toString(), width: 100, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },
			{ field: "fax", title: this.$i18n.t("generico.fax").toString(), width: 100, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },
			{
				field: "codicePagamentoID",
				title: this.$i18n.t("generico.pagamento").toString(),
				width: 180,
				groupable: true,
				hidden: false,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				values: this.codiciPagamento,
				sortBy: "codicePagamento",
				filterable: true,
				itemsForFilter: this.codiciPagamento,
				filterType: "select",
				editType: "select",
				editSource: this.codiciPagamento,
				editSourceText: "text",
				editSourceValue: "value",
				editSelectionCallBack: this.onChangePagamento,
			},
			{
				field: "descrizione",
				title: this.$i18n.t("generico.pagamento").toString(),
				hidden: true,
				editHide: true,
				export: true
			},
			{
				field: "pagamentoLimiteCredito",
				title: this.$i18n.t("generico.limiteCredito").toString(),
				width: 150,
				hidden: true,
				type: "numeric-int",
				format: "{0:n0}",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				editHide: this.hideLimiteCredito
			},
			{ field: "partitaIva", title: this.$i18n.t("generico.partitaIva").toString(), export: true, width: 100, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate" },
			{ field: "indirizzo", title: this.$i18n.t("generico.indirizzo").toString(), hidden: true, width: 150, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true },
			{ field: "localita", title: this.$i18n.t("generico.localita").toString(), width: 100, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true },
			//{ field: "regione", title: this.$i18n.t("generico.regione").toString(), width: 100, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true },
			{
				field: "codiceRegione",
				fieldSourceText: "text",
				fieldSourceValue: "code",
				title: this.$i18n.t("generico.regione").toString(),
				width: 80,
				groupable: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				values: this.regioni,
				sortBy: "regione",
				filterable: true,
				filterSourceText: "text",
				filterSourceValue: "code",
				itemsForFilter: this.regioni,
				filterType: "select",
				editSourceText: "text",
				editSourceValue: "code",
				editHide: true
			},
			{
				field: "codiceProvincia",
				title: this.$i18n.t("generico.provincia").toString(),
				width: 80,
				groupable: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				values: this.province,
				sortBy: "provincia",
				filterable: true,
				itemsForFilter: this.province,
				filterType: "select",
				editType: "select",
				editSource: this.province,
				editSourceText: "text",
				editSourceValue: "code",
				hidden: true
			},

			{ field: "provincia", title: this.$i18n.t("generico.provincia").toString(), hidden: true, export: true, editHide: true },
			{ field: "cap", title: this.$i18n.t("generico.cap").toString(), width: 200, hidden: true, headerCell: "myHeaderCellTemplate" },
			{
				field: "commissioni",
				title: this.$i18n.t("generico.commissioni").toString(),
				width: 150,
				type: "numeric",
				hidden: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
			},
			{
				field: "linguaID",
				title: "Lingua",
				width: 100,
				groupable: true,
				hidden: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				values: this.lingue,
				sortBy: "lingua",
				filterable: true,
				itemsForFilter: this.lingue,
				filterType: "select",
				editType: "select",
				editSource: this.lingue,
				editSourceText: "text",
				editSourceValue: "value"
			},
			{
				field: "calcoloECInizioViaggio",
				title: this.$i18n.t("generico.ecSuInizioViaggio").toString(),
				width: 100,
				hidden: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				editType: "switch"
			},
			{
				field: "note",
				title: this.$i18n.t("generico.note").toString(),
				width: 100,
				hidden: true,
				groupable: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				editType: "textarea",
				export: true
			},
			{
				field: "bannerHPInterna",
				title: "Banner",
				width: 80,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\BannerHP",
				editField: "bannerHPInterna_file",
				export: true
				// template: function(dataItem) {
				// 	if (dataItem.BannerHPInterna) return "<img class='imgDistributoriBannerHP' src='~/App_Themes/Webins/Immagini/BannerHP/" + dataItem.BannerHPInterna + "'></img>";
				// 	else return "";
				// }
			},
			{
				title: this.$i18n.t("generico.bannerHP").toString(),
				width: 80,
				// template: function(dataItem) {
				// 	var tmlp = kendo.template($("#uploadBannerHP").html());
				// 	return tmlp(dataItem);
				// },
				hidden: true,
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate"
			},
			{
				field: "cancellato",
				title: this.$i18n.t("generico.nonAttivo").toString(),
				width: 80,
				disabled: true,
				type: "boolean",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				filterType: "boolean",
				export: true
			},
			{
				field: "istanteInserimento",
				title: "Istante Inserimento",
				type: "datetime",
				editable: false,
				hidden: true,
				format: "{0: dd/MM/yyyy HH:mm}",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				editHide: this.isNewItem
			},
			{
				field: "istanteModifica",
				title: this.$i18n.t("generico.istanteModifica").toString(),
				type: "datetime",
				editable: false,
				hidden: true,
				format: "{0: dd/MM/yyyy HH:mm}",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				editHide: this.isNewItem
			},
			{
				field: "controlloIddEscluso",
				title: this.$i18n.t("generico.controlloIddEscluso").toString(),
				type: "boolean",
				cell: "defaultCellTemplate",
				headerCell: "myHeaderCellTemplate",
				export: true,
				hidden: true,
				editType: "boolean"
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" },
			{ field: "_ck_select", title: " ", width: 50, cell: "defaultCellTemplate", type: "ck_select", filterable: false, headerCell: "myHeaderCellTemplate", headerType: "boolean" }
		];
	}

	sort: any = [{ field: "ragioneSociale", dir: "asc" }];

	created() {}

	mounted() {
		var self = this;

		Promise.all([
			api.getDatiDropDown(api.endpoint.DROPDOWN.DISTRIBUTORI).then(res => (self.distributori = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.TIPO_DISTRIBUTORE).then(res => (self.tipiDistributore = res)),
			api.getDatiDropDownAny(api.endpoint.DROPDOWN.CODICIPAGAMENTO).then(res => (self.codiciPagamento = res)),
			api.getDatiDropDown(api.endpoint.DROPDOWN.PROVINCE).then(res => {
				// self.province = (res as TextValueExtended[]).map(function (obj) {
				// 	obj.value = parseInt(obj.code);
				// 	return obj;
				// });

				self.province = res as TextValueExtended[];
				// for (var i = 0; i < self.province.length; i++) {
				// 	self.province[i].value = self.province[i].code;
				// }
			}),
			api.getDatiDropDown(api.endpoint.DROPDOWN.REGIONI).then(res => {
				self.regioni = res as TextValueExtended[];
				// for (var i = 0; i < self.regioni.length; i++)
				// 	self.regioni[i].value = self.regioni[i].code;
				// self.regioni = (res as TextValueExtended[]).map(function (obj) {
				// 	obj.value = parseInt(obj.code);
				// 	return obj;
				// });
			}),
			api.getDatiDropDown(api.endpoint.DROPDOWN.LINGUE).then(res => (self.lingue = res))
		])
			.then(responses => {
				if (self.showOnlyActive) {
					self.filter.filters.push({
						field: "cancellato",
						operator: "eq",
						value: "!!false!!"
					});
				}

				self.setDefaultPageSize();
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	onChangeTipoDistributore(val, obj){
		this.hintTipoDistributore = obj ? obj.code || "" : "";
	}

	onChangePagamento(val, obj){
		this.hideLimiteCredito = obj && obj.verificaLimite ? false : true;
	}

	@Watch("showOnlyActive")
	onShowOnlyActiveChange(newValue: boolean) {
		const field = "cancellato";
		if (newValue) {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!false!!"
			});

			this.setDefaultPageSize();
			this.getData();
		} else {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();
		}
	}

	addNewRow() {
		this.onEdit(new AnagraficaDistributoreEdit());
	}

	eseguiSuSelezione() {
		if (this.selectedActionToDo) {
			let cmd = this.selectedActionToDo == 1 ? "PropagaProdottiAiFigli" : "EreditaProdottiDalPadre";
			if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
				let elementToDoAction = this.gridData.data
					.filter(function(item) {
						return item._ck_select == true;
					})
					.map(function(el) {
						return el.itemID;
					});
				for (let i = 0; i < elementToDoAction.length; i++) {
					let element = elementToDoAction[i];

					let data: EseguiComandoRequest = {
						comando: cmd,
						elenco: [],
						parametri: [element]
					};
					api.eseguiComando(data)
						.then(res => {
							this.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
								variant: "success",
								title: this.$i18n.t("generico.azioneeseguita").toString(),
								solid: true
							});
						})
						.catch(err => {
							this.$bvToast.toast(this.$i18n.t("generico.erroreGenerico").toString(), {
								variant: "error",
								title: this.$i18n.t("generico.errore").toString(),
								solid: true
							});
						});
				}
			} else {
				this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.noactionselected").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
		// var cmd = $("#comando").val();
		// if (cmd == 0) return;
		// var grid = $("#grid").data("kendoGrid");
		// var eseguito = false;
		// $(".clSeleziona:checked").each(function(idx, el) {
		// 	eseguito = true;
		// 	var tr = $(el).closest("tr");
		// 	var uid = tr.data("uid");
		// 	var data = grid.dataItem(tr);
		// 	var esegui = "";
		// 	if (cmd == 1) esegui = "PropagaProdottiAiFigli";
		// 	if (cmd == 2) esegui = "EreditaProdottiDalPadre";

		// 	var dati = kendo.stringify({ Comando: esegui, Elenco: [], Parametri: [data.ItemID] });
		// 	WebService.eseguiCmd(
		// 		dati,
		// 		function() {
		// 			alert("Operazione eseguita.");
		// 		},
		// 		function(error) {
		// 			alert("Errore : " + kendo.stringify(error));
		// 		}
		// 	);
		// });
		// if (!eseguito) {
		// 	alert("E' necessario selezionare almeno una riga!");
		// }
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	getData() {
		var self = this;
		this.getGridData(_ENDPONT);
	}
	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<strong>${item.partitaIva}</strong><h5>${item.ragioneSociale}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.distributore")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}
	onEdit(item: any) {
		var self = this;

		self.editItem = Object.assign(new AnagraficaDistributoreEdit(), item);
		self.editSubTitle = item.ragioneSociale;
		self.editTitle = item.partitaIva || item.codiceFiscale;
		self.showEditModal = true;
	}
	editTitle: string = null;
	editSubTitle: string = null;
	get isNewItem(): boolean {
		var self = this;
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
	onSaveEdit() {
		var self = this;

		api.setDistributore(self.editItem, self.isNewItem)
			.then(res => {
				this.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.distributore") }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});

				self.getData();
				self.showEditModal = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	onCellClick(dataItem: any) {
		console.log(dataItem);
	}
}
